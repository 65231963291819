<template>
  <section id="sb-trans" class="menu-sidebar sb-down">
    <div class="wrapmenu">
      <ul class="navbar-nav">
        <template v-for="(menu,index) in sidebarmenu.menuitems">
          <li class="nav-item dropdown" v-if="menu.itemschild !== undefined && menu.itemschild.length > 0">
            <!-- voce con sottomenu -->
            <a class="bigger-menu-text" href="javascript:void(0);" v-on:click="toggleSubMenu('shop')">
              {{ menu.name }}
              <img class="icon_shop" id="shop-submenu" src="/svg/angle-right-solid.svg" alt="">
              <!--<img v-if="this.shop" class="icon" src="/svg/angle-down-solid.svg" alt="">-->
            </a>
            <div class="dropdown-menu" :class="{ show: shop }">
              <!-- dropdown delle categorie abbigliamento senza accessori,scarpe e gift card-->
              <ul class="navbar-nav mr-auto">
                <!-- layer 2 -->
                <!-- voce con sottomenu 2-->
                <template v-for="child in menu.itemschild">
                  <li class="nav-item dropdown" v-if="child.itemschild !== undefined && child.itemschild.length > 0">
                    <a class="bigger-menu-text" href="javascript:void(0);" v-on:click="toggleSubMenu('clothing')">
                      {{ child.name }}
                      <img class="icon_shop" id="clothing-submenu" src="/svg/angle-right-solid.svg" alt="">
                      <!--<img v-if="this.shop" class="icon" src="/svg/angle-down-solid.svg" alt="">-->
                    </a>
                    <div class="dropdown-menu" :class="{ show: clothing }">
                      <!-- dropdown delle categorie abbigliamento senza accessori,scarpe e gift card-->
                      <ul class="navbar-nav mr-auto">
                        <template v-for="child2 in child.itemschild">
                          <li>
                            <router-link class="big-menu-text" :to="child2.link" v-on:click="toggleSidebar">
                              {{ child2.name }}
                            </router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </li>
                  <li v-else>
                    <!-- voce normale -->
                    <router-link class="dropdown-toggle big-menu-text" :to="child.link" v-on:click="toggleSidebar">
                      {{ child.name }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </li>
          <li class="nav-item" v-else>
            <!-- voce normale -->
            <router-link class="bigger-menu-text" :to="menu.link" v-on:click="toggleSidebar">
              {{ menu.name }}
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <div class="wrapmenu">
      <hr class="border-top">
    </div>
    <div class="wrapmenu mb-3">
      <ul>
        <li>
          <router-link to="/profile" class="menu-text" v-on:click="toggleSidebar">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 448 512">
              <path
                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
            </svg>
            <span class="ml-3"> {{ user.name ? user.name.toUpperCase() : "PROFILO" }} </span>
          </router-link>
        </li>
        <li v-if="auth !== false" class="nav-item">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon mr-3 mb-2" viewBox="0 0 512 512">
            <path
                d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"/>
          </svg>
          <button type="button" class="logout-button menu-text" v-on:click="logout()">
            ESCI
          </button>
        </li>
        <li>
          <router-link to="/cart" class="menu-text d-inline-flex" v-on:click="toggleSidebar">
            <div style="position: relative; margin-bottom: 3px">
              <img src="/svg/bag-shopping-solid.svg" class="mt-3 icon mb-1" alt="">
              <p style="margin-left: 9px; margin-top: 8px; position: absolute; left: 20%;"
                 class="badgecart" v-if="cartProds"> {{ cartProds }} </p>
            </div>
            <span class="ml-3 mt-3">CARRELLO</span>
          </router-link>
        </li>
        <li>
          <router-link to="/favourites" class="menu-text d-inline-flex" v-on:click="toggleSidebar">
            <div style="position: relative; margin-bottom: 3px">
              <svg xmlns="http://www.w3.org/2000/svg" class="mt-3 icon mb-1" viewBox="0 0 512 512">
                <path
                    d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
              </svg>
              <p style="margin-left: 9px; margin-top: 8px; position: absolute; left: 20%; "
                 class="badgecart" v-if="favProds"> {{ favProds }} </p>
            </div>
            <span class="mt-3 ml-3">PREFERITI</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="wrapmenu mt-4 mb-4">
      <div class="d-flex justify-content-around">
        <a href="https://www.facebook.com/lamoraglamour">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon">
            <path
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11  71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/la_mora_glamour/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon">
            <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
          </svg>
        </a>
        <a href="https://www.youtube.com/@LaMORAglamour">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

import Search from "@/components/Search";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sidebar",
  props: ['sidebarmenu'],
  data() {
    return {
      opened: false,
      shop: false,
      clothing: false
    }
  },
  computed: {
    cartProds() {
      return this.$store.getters.getCartTotalProducts;
    },
    favProds() {
      return this.$store.getters.getFavTotalProducts;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  components: {Search},
  mounted() {
    this.$store.dispatch('getCategories');
    this.$store.dispatch('getProfileData');
  },
  methods: {
    toggleSubMenu(type) {
      let shopsm = document.getElementById("shop-submenu");
      let clothsm = document.getElementById("clothing-submenu");
      if (type === "shop") {
        if (!shopsm.classList.contains("icon_shop_rotate")) {
          shopsm.classList.remove("icon_shop_derotate");
          shopsm.classList.add("icon_shop_rotate");
          this.shop = true;
        } else if (shopsm.classList.contains("icon_shop_rotate")) {
          shopsm.classList.remove("icon_shop_rotate");
          shopsm.classList.add("icon_shop_derotate");
          this.shop = false;
        }
      } else if (type === "clothing") {
        if (!clothsm.classList.contains("icon_shop_rotate")) {
          clothsm.classList.remove("icon_shop_derotate");
          clothsm.classList.add("icon_shop_rotate");
          this.clothing = true;
        } else if (clothsm.classList.contains("icon_shop_rotate")) {
          clothsm.classList.remove("icon_shop_rotate");
          clothsm.classList.add("icon_shop_derotate");
          this.clothing = false;
        }
      }
    },
    toggleSidebar() {
      this.$store.state.openedSidebar = !this.$store.state.openedSidebar;
    },
    logout() {
      this.$store.dispatch('logout');
    },
    openSearch() {
      this.$store.state.opened = true;
    },
    slugify(text) {
      return text.toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase();
    },
  }
}
</script>

<style scoped>
ul {
  padding-left: 0px;
}

a {
  text-decoration: none !important;
}

.menu-sidebar {
  width: 100%;
  height: 100%;
  top: 71px;
  left: 0;
  position: fixed;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto; /* Aggiunta per lo scrolling */
  max-height: calc(100vh - 71px); /* Aggiunta per altezza massima */
}


.menu-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-toggle::after {
  display: none;
}

.menu-sidebar .nav-item {
  margin: 9px 0 9px 0;
}

.navbar-nav .nav-item.dropdown .nav-item.dropdown {
  margin: 0;
}

.menu-sidebar .dropdown-menu li a {
  text-transform: uppercase;
  font-size: 21px;
}

.dropdown-item:active, .dropdown-item:hover {
  background-color: #dedede;
}

.navbar-nav {
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.icon {
  width: 20px;
  fill: black;
}

.icon_shop {
  margin-top: -3px;
  width: 16px;
  fill: black;
}

.sb-down {
  animation-name: slideInDown;
  animation-duration: 0.5s;
}

.icon_shop_rotate { /* non cancellare */
  transition-duration: 0.3s;
  transform: rotate(90deg);
}

.icon_shop_derotate { /* non cancellare */
  transition-duration: 0.3s;
  transform: rotate(0deg);
}

.icon_abb {
  width: 14px;
  fill: black;
}

.border-top {
  color: grey;
}

.logout-button {
  background-color: white;
  border: none;
}

.bg-sidebar {
  background-color: white;
}

.bigger-menu-text {
  font-size: 25px;
  color: #000;
  font-family: oswald;
}

.big-menu-text {
  font-size: 21px;
  color: #000;
  font-family: oswald;
}

.menu-text {
  font-size: 18px;
  color: #000;
  font-family: oswald;
}

.dropdown-item {
  padding: 0;
}

.menu-sidebar ul li {
  width: 100%;
  margin-left: 0 !important;
}

.menu-sidebar ul li a {
  width: 100%;
  display: block;
}

.badgecart {
  background-color: black;
  color: white;
  border-radius: 50%;
  position: relative;
  padding: 0 5px;
  margin-left: 14px !important;
  top: -1px !important;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

.menu-sidebar .dropdown-menu {
  width: 100%;
  margin-top: 10px !important;
  padding-left: 15px !important;
  position: relative !important;
  transform: none !important;
  background-color: #fff;
  border-left: 2px solid #dedede;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.wrapmenu {
  width: 100%;
  float: left;
}


/*.menu-sidebar .dropdown-menu a {
  color: #000;
  text-align: center;
} */

/*.menu-sidebar .dropdown-menu a:hover,
.menu-sidebar .dropdown-menu a:focus {
  color: #000;
}*/


</style>