import { createApp } from 'vue'
import router from "./router"
import store from "./store"
import App from './App.vue'
import './interceptors/axios';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css'

library.add(faAngleUp);

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).mount('#app')
